











import { Component, Vue } from 'vue-property-decorator'
import ServicesForm from '@/partials/forms/ServicesForm.vue'

@Component({
  components: {
    ServicesForm
  }
})
export default class ServicePublicCreate extends Vue {

}
